import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  text-align: center;
  color: white;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 2rem;
`;

const AboutPage = () => {
  return (
    <Layout title="About">
      <SEO pagetitle="About" pagedesc="ShogiStats について" />
      <ContentWrapper>
        <Main>
          <StyledH1>ShogiStats について</StyledH1>
          <p>個人学習のアウトプット用サイトです。</p>
          <p>
            せっかくお越しいただいたのに、どうでもいい内容ばかりで申し訳ございません。
          </p>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export default AboutPage;
